<template>
  <div class="content contribute-audit">
    <a-tabs :active-key="key" @tabClick="handleClick">
      <a-tab-pane :key="1">
        <template #tab>
          <a-badge :count="count[0]" :offset="[10, 0]">
            <div class="contribute-tab-text">感悟分享</div>
          </a-badge>
        </template>
        <contribute-data audit :type="1" @update="handleUpdate" />
      </a-tab-pane>
      <a-tab-pane :key="2">
        <template #tab>
          <a-badge :count="count[1]" :offset="[10, 0]">
            <div class="contribute-tab-text">摄影分享</div>
          </a-badge>
        </template>
        <contribute-data audit :type="2" @update="handleUpdate" />
      </a-tab-pane>
      <a-tab-pane :key="3">
        <template #tab>
          <a-badge :count="count[2]" :offset="[10, 0]">
            <div class="contribute-tab-text">视频分享</div>
          </a-badge>
        </template>
        <contribute-data audit :type="3" @update="handleUpdate" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
    import ContributeData from './laytouts/ContributeData';
    export default {
        name: "ContributeAudit",
        components: {ContributeData },
        data() {
            return {
                key: 1,
                count: [0,0,0]
            }
        },
        created() {
            this.getCount();
        },
        methods: {
            handleUpdate(item) {
                if(item.status > 1) {
                    const count = [...this.count];
                    const num = count[item.type - 1] - 1;
                    count[item.type - 1] = Math.max(num, 0);
                    this.count = count;
                }
            },
            handleClick(e) {
                this.key = e;
            },
            getCount() {
                this.$axios({
                    url: "/admin/mini-contribute/count",
                    noTempleFilter: true
                }).then(res => {
                    if (res.error === 0) {
                        const { perception, photography, video } = res.data;
                        this.count = [perception * 1 || 0, photography * 1 || 0, video * 1 || 0];
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            }
        }
    }
</script>

<style lang="less">
.contribute-audit {
  .ant-tabs {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .ant-tabs-content {
    flex: 1;
    min-height: 0;
  }
}
</style>
